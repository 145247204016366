/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalError } from "@common/kernel/custom-error";

import ErrorCode from "@common/kernel/error-code";
import { IGetUserByUsernameResponse, IUpdateUserBody } from "@domain/interfaces/chat/user-interface";

export default abstract class UserRepository {
  async getUserByUsername (username: string): Promise<IGetUserByUsernameResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  async updateUserByUsername (username: string, body: IUpdateUserBody): Promise<IGetUserByUsernameResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
