import { injectable } from "inversify";

import { IGetUserByUsernameResponse, IUpdateUserBody } from "@domain/interfaces/chat/user-interface";
import UserRepository from "@domain/repositories/chat/user-repository";
import ApiService from "@infrastructure/network/api";

@injectable()
export default class UserApiRepository implements UserRepository {
  private apiService = new ApiService();

  async getUserByUsername (username: string): Promise<IGetUserByUsernameResponse> {
    const result = await this.apiService.get(`/chat/user/detail/${username}`, {});

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }

  async updateUserByUsername (username: string, body: IUpdateUserBody): Promise<IGetUserByUsernameResponse> {
    const result = await this.apiService.put(`/chat/user/${username}`, body);

    return {
      isSuccess: result.code === 1,
      message: result.message || "",
      data: result.data,
    };
  }
}
