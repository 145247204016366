import { routesName } from "@common/route";
import { paramCase } from "change-case-all";

export type AccountMenu = {
  name: string;
  path: string;
  subMenu: string;
};

export const subMenu = {
  configSub: {
    key: "configSub",
    sort: 1,
  },
  contentSub: {
    key: "contentSub",
    sort: 2,
  },
  accountSub: {
    key: "accountSub",
    sort: 3,
  },
  notificationSub: {
    key: "notificationSub",
    sort: 4,
  },
  employeeSub: {
    key: "employeeSub",
    sort: 5,
  },
  foodSub: {
    key: "foodSub",
    sort: 6,
  },
  customerSub: {
    key: "customerSub",
    sort: 7,
  },
  callSub: {
    key: "callSub",
    sort: 8,
  },
  chatSub: {
    key: "chatSub",
    sort: 9,
  },
  miniApp: {
    key: "miniAppSub",
    sort: 10,
  },
  event: {
    key: "eventSub",
    sort: 11,
  },
};

export const getSubMenuByRouteName = (routeName?: string) => {
  if (!routeName) return "";

  if (
    [
      routesName.admin.config,
      routesName.admin.appVersion,
      routesName.admin.homeMenu,
      routesName.admin.testPhone,
      routesName.admin.segment,
      routesName.admin.popup,
    ].includes(routeName)
  )
    return subMenu.configSub.key;

  if ([routesName.admin.customerRecord].includes(routeName)) return subMenu.customerSub.key;

  if ([routesName.admin.user, routesName.admin.checkIn].includes(routeName)) return subMenu.employeeSub.key;

  if (
    [
      routesName.admin.bookingMeal,
      routesName.admin.bookingMealFeedback,
      routesName.admin.bookingMealConfig,
      routesName.admin.bookingMealGroup,
    ].includes(routeName)
  )
    return subMenu.foodSub.key;

  if (
    [routesName.admin.notification, routesName.admin.pushNotification, routesName.admin.sendMessage, routesName.admin.notificationSchedule].includes(
      routeName
    )
  )
    return subMenu.notificationSub.key;

  if ([routesName.admin.account, routesName.admin.role, routesName.admin.rule].includes(routeName)) return subMenu.accountSub.key;

  if ([routesName.admin.callAgent, routesName.admin.callNumber, routesName.admin.callQueue].includes(routeName)) return subMenu.callSub.key;

  if ([routesName.admin.conversation, routesName.admin.chatPermission].includes(routeName)) return subMenu.chatSub.key;

  if ([routesName.admin.miniApp].includes(routeName)) return subMenu.miniApp.key;

  if ([routesName.admin.luckyMoney, routesName.admin.event].includes(routeName)) return subMenu.event.key;

  return "";
};

export const getAccountMenus = (menuList: AccountMenu[]) => {
  return Object.values(subMenu)
    .map((subItem) => {
      return {
        key: subItem.key,
        sort: subItem.sort,
        children: menuList.filter((menu) => menu.subMenu === subItem.key),
        path: "",
      };
    })
    .concat(
      menuList
        .filter((menu) => !menu.subMenu)
        .map((menu) => {
          return {
            key: menu.name,
            path: menu.path,
            sort: 0,
            children: [],
          };
        })
    )
    .sort((a, b) => a.sort - b.sort)
    .filter((item) => item.children.length > 0);
};

export const menuObject = (name: string) => ({
  name,
  path: `/${paramCase(name)}`,
  subMenu: getSubMenuByRouteName(name),
});

export const superAdminMenu: AccountMenu[] = Object.keys(routesName.admin).map((name) => {
  return menuObject(name);
});
